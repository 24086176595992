<template>
  <div class="ticketDetails">
    <div class="info_box">
      <h3>{{ data.activityName || data.exhibitName }}</h3>
      <p>
        {{ typeText }}时间：{{
          (data.activityDate || data.exhibitDate) +
          ' ' +
          (data.activityStartTime || data.exhibitStartTime) +
          '-' +
          (data.activityEndTime || data.exhibitEndTime)
        }}
      </p>
      <p>
        {{ typeText }}地点：{{ data.activityAddress || data.exhibitAddress }}
      </p>
      <p>
        <span>报名人数：{{ orderInfo.totalNum }}人</span>
      </p>
      <div v-if="data.needPay" class="needPay">
        <p>
          {{ typeText }}费用：<span style="color: #fd4d4f"
            >￥{{ data.price || 0 }}</span
          >
        </p>
        <p
          v-if="
            [0, 1, 2, 3].includes(
              orderInfo.activityStatus !== undefined
                ? orderInfo.activityStatus
                : orderInfo.exhibitStatus
            )
          "
        >
          <span style="color: #fd4d4f"
            >{{ orderInfo.payStatus ? '实付' : '需支付' }}：￥{{
              data.price || 0
            }}</span
          >
        </p>
      </div>
      <div class="status" :style="{ backgroundColor: statusColor }">
        {{ statusText }}
      </div>
    </div>
    <div class="box">
      <VoucherCodeActivity
        :orderId="orderInfo.id"
        :qrcode="qrcode"
        v-if="qrcode && qrcode.length"
      />
      <MyBox
        type="紧急联系人"
        title="紧急联系人"
        :appointedByList="[orderInfo]"
        v-if="[orderInfo].length"
      />
      <MyBox
        type="参与者"
        title="用户信息"
        :field="data.field"
        :accompanyingList="accompanyingList"
        v-if="accompanyingList.length"
      />
      <div class="information" v-if="orderList.length">
        <div>订单信息</div>
        <p v-for="(item, index) in orderList" :key="index">
          <span>{{ item.name }}：{{ item.value }}</span>
        </p>
      </div>
      <div
        class="information"
        v-if="
          [1].includes(
            orderInfo.activityStatus !== undefined
              ? orderInfo.activityStatus
              : orderInfo.exhibitStatus
          )
        "
      >
        <div>签到信息</div>
        <p>
          <span>签到时间：{{ orderInfo.checkTime }}</span>
        </p>
      </div>
    </div>
    <div
      class="bottom_box"
      v-if="
        [-2, 0].includes(
          orderInfo.activityStatus !== undefined
            ? orderInfo.activityStatus
            : orderInfo.exhibitStatus
        ) && [1, 3].includes(data.status)
      "
    >
      <van-button color="#fd4d4f" plain @click="toRefund">取消</van-button>
      <van-button
        color="#0B6CF9"
        plain
        @click="toPay"
        v-if="
          data.needPay &&
          !orderInfo.payStatus &&
          [0].includes(
            orderInfo.activityStatus !== undefined
              ? orderInfo.activityStatus
              : orderInfo.exhibitStatus
          )
        "
        >去支付</van-button
      >
    </div>
  </div>
</template>
<script>
import MyBox from '@/components/MyBox.vue';
import VoucherCodeActivity from '@/components/VoucherCodeActivity.vue';
import {
  activityInfo,
  activityCancel,
  activity_order_pay,
  exhibitInfo,
  exhibitCancel,
  exhibit_order_pay,
} from '@/api/MyTicket';
export default {
  name: 'TicketDetails',
  data() {
    return {
      type: '',
      typeText: '',
      id: null,
      accompanyingList: [],
      orderList: [],
      infoApi: null,
      refundApi: null,
      orderPayApi: null,
      data: {},
      orderInfo: {},
      statusText: null,
      statusColor: null,
      qrcode: [],
    };
  },
  computed: {},
  components: { MyBox, VoucherCodeActivity },
  created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    if (this.type === '活动报名详情') {
      this.typeText = '活动';
      this.infoApi = activityInfo;
      this.refundApi = activityCancel;
      this.orderPayApi = activity_order_pay;
    } else if (this.type === '临展报名详情') {
      this.typeText = '临展';
      this.infoApi = exhibitInfo;
      this.refundApi = exhibitCancel;
      this.orderPayApi = exhibit_order_pay;
    }
    this.getInfo();
  },
  mounted() {},
  methods: {
    async getInfo() {
      const arr = [
        '',
        '身份证',
        '护照',
        '往来港澳通行证/港澳居民来往内地通行证',
        '往来台湾通行证/台湾居民来往大陆通行证',
        '外国人永久居留证（Foreign Permanent Resident ID Card）',
      ];
      let form = {};
      if (this.typeText === '活动') {
        form.activityOrderId = this.id;
      } else {
        form.exhibitOrderId = this.id;
      }
      let {
        data: {
          activityOrder,
          activityOrderItemList,
          activity,
          exhibitOrder,
          exhibitOrderItemList,
          exhibit,
        },
      } = await this.infoApi({ ...form });
      let {
        documentType,
        activityStatus,
        exhibitStatus,
        payStatus,
        payTime,
        createTime,
        refundTime,
      } = activityOrder || exhibitOrder;
      let { field, chooseTime, needChoose, needPay, price } =
        activity || exhibit;
      let Status =
        activityStatus !== undefined ? activityStatus : exhibitStatus;
      if (this.typeText === '活动') {
        activity.field = field ? field.split(',') : [];
        activityOrder.documentTypeText = arr[documentType];
        this.data = activity;
        this.orderInfo = activityOrder;
        activityOrderItemList.map((item) => {
          item.documentTypeText = arr[item.documentType];
        });
        this.accompanyingList = activityOrderItemList;
      } else {
        exhibit.field = field ? field.split(',') : [];
        exhibitOrder.documentTypeText = arr[documentType];
        this.data = exhibit;
        this.orderInfo = exhibitOrder;
        exhibitOrderItemList.map((item) => {
          item.documentTypeText = arr[item.documentType];
        });
        this.accompanyingList = exhibitOrderItemList;
      }
      this.orderList = [{ name: '报名时间', value: createTime }];
      if (Status !== -2) {
        if (needChoose) {
          this.orderList.push({ name: '摇号时间', value: chooseTime });
        }
        if (payStatus && needPay) {
          this.orderList.push({ name: '支付时间', value: payTime });
        }
      }
      switch (Status) {
        case -2:
          this.statusText = '待摇号';
          this.statusColor = '#fd4d4f';
          break;
        case -1:
          this.statusText = '未摇中';
          this.statusColor = '#cccccc';
          break;
        case 0:
          if (payStatus) {
            this.setQrcode();
            this.statusText = '待参加';
            this.statusColor = '#4bd863';
          } else {
            this.statusText = '待支付';
            this.statusColor = '#fd4d4f';
          }
          break;
        case 1:
          this.statusText = '已签到';
          this.statusColor = '#cccccc';
          break;
        case 2:
          this.statusText = '已取消';
          this.statusColor = '#cccccc';
          this.orderList.push({ name: '取消时间', value: refundTime });
          if (payStatus && needPay) {
            this.orderList.push({ name: '退款金额', value: price });
          }
          break;
        case 3:
          this.statusText = '已违约';
          this.statusColor = '#fd4d4f';
          break;
        default:
          break;
      }
    },
    setQrcode() {
      this.qrcode = [];
      if (this.accompanyingList && this.accompanyingList.length) {
        this.accompanyingList.map((v) => {
          this.qrcode.push({ name: v.name, id: v.id });
        });
      }
    },
    //退票
    toRefund() {
      this.$dialog
        .confirm({
          message: '是否确定要退此票,此操作不能返回。',
        })
        .then(() => {
          let form = {};
          if (this.typeText === '活动') {
            form.activityOrderId = this.id;
          } else {
            form.exhibitOrderId = this.id;
          }
          this.refundApi({ ...form }).then((res) => {
            if (res.code === 200) {
              this.$notify({
                type: 'success',
                message: res.msg,
              });
              this.qrcode = [];
              this.getInfo();
            } else {
              this.$notify({
                type: 'danger',
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    async toPay() {
      let { activityId, exhibitId, id, orderNo } = this.orderInfo;
      let data = {
        id,
        orderNo,
      };
      if (this.typeText === '活动') {
        data.activityId = activityId;
      } else {
        data.exhibitId = exhibitId;
      }
      let {
        data: { payUrl },
      } = await this.orderPayApi(data);
      window.location.href = payUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.ticketDetails {
  background: #f5f6fa;
  min-height: calc(100vh - 112px);
  padding: 10px 10px 56px;
  .info_box {
    position: relative;
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      font-size: 16px;
      color: #333;
      line-height: 30px;
      width: calc(100% - 70px);
    }
    p {
      font-size: 14px;
      color: #666;
      line-height: 25px;
    }
    .needPay {
      display: flex;
      justify-content: space-between;
    }
    .status {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      color: #fff;
      height: 30px;
      line-height: 30px;
      text-align: right;
      padding: 0 15px 0 20px;
      border-radius: 0 0 0 30px;
    }
  }
  .box {
    > div {
      margin-top: 10px;
    }
    .information {
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      div {
        margin: 0 0 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececec;
        font-weight: 500;
        color: #333333;
        font-size: 14px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 24px;
      }
    }
  }
  .bottom_box {
    height: 46px;
    padding: 0 20px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .van-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      padding: 0;
      margin-left: 10px;
    }
  }
}
</style>
